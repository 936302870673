import { Select } from "native-base";
import { useSupplierContext } from "../../contexts";
import React from "react";
import {
  ISupplierItem,
  getSuppliersService,
} from "../../services/suppliersService";
import { ParamListBase, useFocusEffect } from "@react-navigation/native";
import { DrawerNavigationProp } from "@react-navigation/drawer";

export const SelectProvider = ({
  navigation,
  whiteMode,
}: {
  navigation: DrawerNavigationProp<ParamListBase>;
  whiteMode?: boolean;
}) => {
  const { currentSupplier, setCurrentSupplier } = useSupplierContext();
  const [supplierList, setSupplierList] = React.useState<ISupplierItem[]>();
  
  useFocusEffect(
    React.useCallback(() => {
      async function getSupplierList() {
        const data = await getSuppliersService();
        if (data?.status) setSupplierList(data?.result);
      }

      getSupplierList();
    }, [])
  )

  function handleSelect(itemValue: string) {
    setCurrentSupplier(itemValue);
    navigation.navigate('HomeScreen');
  }

  return (
    <Select
      selectedValue={currentSupplier}
      minWidth="150"
      height={8}
      accessibilityLabel="Selecione um fornecedor"
      placeholder="Selecione"
      _selectedItem={{
        bg: "teal.600",
      }}
      color={whiteMode ? "#fff" : null}
      mt={1}
      onValueChange={(itemValue) => handleSelect(itemValue)}
    >
      {supplierList?.map((item) => (
        <Select.Item
          key={item?.supplier_id}
          label={item?.name}
          value={`${item?.supplier_id}`}
        />
      ))}
    </Select>
  );
};
