import * as React from "react";
import { useWindowDimensions } from "react-native";
import { TabView } from "react-native-tab-view";
import { ItemList } from "../ItemList";
import { renderTabBar } from "../ItemList/renderTabBar";
import { Stack } from "native-base";
import { TextInputSearch } from "../../../../components";
import { Button } from "../../../../components";
import { DrawerNavigationProp } from "@react-navigation/drawer";
import { ParamListBase, RouteProp } from "@react-navigation/native";
import { platformWeb } from "../../../../utils";
import { useInventoryContext } from "../../../../contexts/inventoryContext";

const renderScene = ({ route, searchTerm, handleItemPress }: { route: RouteProp<ParamListBase, string>, searchTerm: string, handleItemPress: any }) => {
  switch (route.key) {
    case "done":
      return <ItemList route={route} status={route.key} searchTerm={searchTerm} handleItemPress={handleItemPress} />;
    case "planned":
      return <ItemList route={route} status={route.key} searchTerm={searchTerm} handleItemPress={handleItemPress} />;
    case "incomplete":
      return <ItemList route={route} status={route.key} searchTerm={searchTerm} handleItemPress={handleItemPress} />;
    default:
      return null;
  }
};

export default function Tabs({ navigation }: { navigation: DrawerNavigationProp<ParamListBase> }) {
  const layout = useWindowDimensions();
  const [index, setIndex] = React.useState(0);
  const [routes] = React.useState([
    { key: "planned", title: "Pendentes" },
    { key: "incomplete", title: "Em Análise" },
    { key: "done", title: "Realizados" },
  ]);
  const [searchInput, setSearchInput] = React.useState("");

  const { searchTerm, updateSearchTerm } = useInventoryContext();

  function handleChangeInput(text: string) {
    setSearchInput(text);
  }

  function handleSearch() {
    updateSearchTerm(searchInput);
  }

  function handleItemPress(id: string, status: string) {
    if (status !== "Planejado" && status !== "CAF terceirizado" && status !== "CAF não está na base") {
      navigation.navigate('ItemDetailScreen', { itemId: id });
    } else if (!platformWeb && status !== "CAF não está na base") {
      navigation.navigate('EnterCodeScreen', { itemId: id });
    }
  }

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        space={1}
        px={1}
        my={2}
      >
        <TextInputSearch
          value={searchInput}
          placeholder="Pesquisar"
          onChangeText={handleChangeInput}
        />
        <Button label="Pesquisar" onPress={handleSearch} />
      </Stack>
      <TabView
        lazy
        navigationState={{ index, routes }}
        renderScene={({ route }) => renderScene({ route, searchTerm, handleItemPress })}
        onIndexChange={setIndex}
        initialLayout={{ width: layout.width }}
        renderTabBar={(props) =>
          renderTabBar({ props, routes, index, setIndex })
        }
      />
    </>
  );
}
