import { Divider, Button, InputDate } from "../../../../components";
import { Text, VStack, Stack, Center, Box, Icon, useTheme, ScrollView, Pressable, } from "native-base";
import { FontAwesome } from "@expo/vector-icons";
import { TextInput } from "../../../../components/TextInput/index";
import { useEffect, useState } from "react";
import * as DocumentPicker from "expo-document-picker";
import { getDivestimentDetailItem, getDivestimentRecDocuments, postDivestimentRecDocuments, putDivestimentRec, putDivestimentRecDocuments } from "./../../../../services/divestimentService";
import { ParamListBase, RouteProp } from "@react-navigation/native";
import { DrawerNavigationProp } from "@react-navigation/drawer";
import Spinner from "react-native-loading-spinner-overlay";
import moment from "moment";
import { Linking } from "react-native";

export const Content = ({ route, navigation }: { route: RouteProp<ParamListBase, string>; navigation: DrawerNavigationProp<ParamListBase>; status: string }) => {
  const { colors } = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [itemDetails, setItemDetails] = useState<any>();
  const [date, setDate] = useState<string>("");
  const [files, setFiles] = useState<any[]>([]);
  const { detailId, headerId, status } = route.params;

  async function getItemDetail() {
    setIsLoading(true);
    if (detailId) {
      const data = await getDivestimentDetailItem(detailId);
      if (data?.status) {
        setItemDetails(data.result);
        if (data.result.last_production_date) {
          setDate(
            moment(data.result.last_production_date)
              .utcOffset(0)
              .format("DD/MM/YYYY")
          );
        }
        if (route.params?.status !== "Em Preenchimento") {
          const res = await getDivestimentRecDocuments(route.params.detailId);
          if (res?.status) {
            setItemDetails({
              ...data.result,
              files: [res?.result],
            });
          }
        }
      }
    }
    setIsLoading(false);
  }

  useEffect(() => {
    getItemDetail();
  }, [route.params]);

  async function saveDetail() {
    setIsLoading(true);
    const data = {
      ...itemDetails,
      last_production_date: moment(date, "DD/MM/YYYY").format("YYYY-MM-DD"),
    };
    delete data["asset_description"];
    delete data["asset_number"];
    if (files.length) {
      await postDivestimentRecDocuments(itemDetails.id, files[0].name)
        .then(async (res: any) => {
          const url = res.result;
          await putDivestimentRecDocuments(url, files[0]);
          const indexOf = url.indexOf("?");
          const reducedUrl = url.substring(0, indexOf);
          data.files = [reducedUrl];
        })
        .catch((err: any) => console.log(err));
    }
    console.log("Update item:", itemDetails.id, data);
    putDivestimentRec(itemDetails.id, data)
      .catch((error: any) => console.log(error))
      .finally(() => {
        setIsLoading(false);
        navigation.navigate("DisinvestmentItemListScreen", {
          headerId: headerId,
        });
      });
  }

  const uploadFile = async () => {
    let result = await DocumentPicker.getDocumentAsync({});
    if (result.type === "success") {
      const file = {
        ...result,
        name:
          itemDetails.asset_number +
          "-" +
          moment().format("YYYYMMDDHmmss") +
          "_" +
          result.name,
      };
      setFiles([file]);
    }
  };

  const handleRemoveFile = (index: number) => {
    setFiles([]);
  };

  const downloadFile = (fileUrl: string) => {
    Linking.openURL(fileUrl).catch((error) => {
      console.error("Error opening URL:", error);
    });
  };

  return (
    <>
      <VStack flex={1} px={2} py={2} _web={{ maxW: 1250 }} bg="#fff">
        <Stack>
          {itemDetails ? (
            <Center pb={2} _web={{ pb: 5 }}>
              <Text>{`${itemDetails.asset_number}`}</Text>
              <Text>{`${itemDetails.asset_description}`}</Text>
            </Center>
          ) : null}
          <Divider />
        </Stack>
        <ScrollView _web={{ h: 700 }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            p={2}
            space={1}
            alignItems="center"
          >
            <Box w="39%">
              <Text>Valor Residual (Saldo) : </Text>
            </Box>
            <Box w="60%">
              <TextInput
                defaultValue={itemDetails?.residual_value?.toString()}
                isDisabled={route.params?.status !== "Em Preenchimento"}
                onChangeText={(value: string) =>
                  setItemDetails({ ...itemDetails, residual_value: value })
                }
              />
            </Box>
          </Stack>
          <Divider />

          <Stack
            direction="row"
            justifyContent="space-between"
            p={2}
            alignItems="center"
          >
            <Box w="40%">
              <Text>Part Number Diretos</Text>
            </Box>
            <Box w="60%">
              <TextInput
                defaultValue={itemDetails?.part_number_direct}
                isDisabled={route.params?.status !== "Em Preenchimento"}
                onChangeText={(value: string) =>
                  setItemDetails({ ...itemDetails, part_number_direct: value })
                }
              />
            </Box>
          </Stack>
          <Divider />

          <Stack
            direction="row"
            justifyContent="space-between"
            p={2}
            alignItems="center"
          >
            <Box w="40%">
              <Text>Part Number Conjunto</Text>
            </Box>
            <Box w="60%">
              <TextInput
                defaultValue={itemDetails?.part_number_group}
                isDisabled={route.params?.status !== "Em Preenchimento"}
                onChangeText={(value: string) =>
                  setItemDetails({ ...itemDetails, part_number_group: value })
                }
              />
            </Box>
          </Stack>
          <Divider />

          <Stack
            direction="row"
            justifyContent="space-between"
            p={2}
            alignItems="center"
          >
            <Box w="40%">
              <Text>Part Number de P&A (reposição)</Text>
            </Box>
            <Box w="60%">
              <TextInput
                defaultValue={itemDetails?.part_number_parts_and_accessories}
                isDisabled={route.params?.status !== "Em Preenchimento"}
                onChangeText={(value: string) =>
                  setItemDetails({
                    ...itemDetails,
                    part_number_parts_and_accessories: value,
                  })
                }
              />
            </Box>
          </Stack>
          <Divider />

          <Stack
            direction="row"
            justifyContent="space-between"
            p={2}
            alignItems="center"
          >
            <Box w="40%">
              <Text>Modelo / Projeto</Text>
            </Box>
            <Box w="60%">
              <TextInput
                defaultValue={itemDetails?.model_project}
                isDisabled={route.params?.status !== "Em Preenchimento"}
                onChangeText={(value: string) =>
                  setItemDetails({ ...itemDetails, model_project: value })
                }
              />
            </Box>
          </Stack>
          <Divider />

          <Stack direction="row" justifyContent="space-between" p={2}>
            <Box w="39%">
              <Text>Última data de Produção</Text>
            </Box>
            <Box w="59%">
              <InputDate
                placeholder="DD/MM/AA"
                value={date}
                editable={route.params?.status === "Em Preenchimento"}
                selectTextOnFocus={route.params?.status === "Em Preenchimento"}
                onChangeText={(value: string) => setDate(value)}
              />
            </Box>
          </Stack>
          <Divider />

          <Stack
            direction="row"
            justifyContent="space-between"
            p={2}
            alignItems="center"
          >
            <Box w="40%">
              <Text>Armazenamento Ferramental</Text>
            </Box>
            <Box w="60%">
              <TextInput
                defaultValue={itemDetails?.tool_storage}
                isDisabled={route.params?.status !== "Em Preenchimento"}
                onChangeText={(value: string) =>
                  setItemDetails({ ...itemDetails, tool_storage: value })
                }
              />
            </Box>
          </Stack>
          <Divider />

          <Stack
            direction="row"
            justifyContent="space-between"
            p={2}
            alignItems="center"
          >
            <Box w="40%">
              <Text>Nº Ciclos Realizados / Ciclo Montagem</Text>
            </Box>
            <Box w="60%">
              <TextInput
                defaultValue={itemDetails?.number_of_cycles_performed?.toString()}
                isDisabled={route.params?.status !== "Em Preenchimento"}
                onChangeText={(value: string) =>
                  setItemDetails({
                    ...itemDetails,
                    number_of_cycles_performed: value,
                  })
                }
              />
            </Box>
          </Stack>
          <Divider />

          <Stack
            direction="row"
            justifyContent="space-between"
            p={2}
            alignItems="center"
          >
            <Box w="40%">
              <Text>Ferramenta / CAF Duplicado</Text>
            </Box>
            <Box w="60%">
              <TextInput
                defaultValue={itemDetails?.asset_number_duplicated}
                isDisabled={route.params?.status !== "Em Preenchimento"}
                onChangeText={(value: string) =>
                  setItemDetails({
                    ...itemDetails,
                    asset_number_duplicated: value,
                  })
                }
              />
            </Box>
          </Stack>
          <Divider />

          <Stack
            direction="row"
            justifyContent="space-between"
            p={2}
            alignItems="center"
          >
            <Box w="40%">
              <Text>Peso da Ferramenta Kg</Text>
            </Box>
            <Box w="60%">
              <TextInput
                defaultValue={itemDetails?.tool_weight_in_kg}
                isDisabled={route.params?.status !== "Em Preenchimento"}
                onChangeText={(value: string) =>
                  setItemDetails({ ...itemDetails, tool_weight_in_kg: value })
                }
              />
            </Box>
          </Stack>
          <Divider />

          <Stack
            direction="row"
            justifyContent="space-between"
            p={2}
            alignItems="center"
          >
            <Box w="40%">
              <Text>Dimensões da Ferramenta (L - C - H)</Text>
            </Box>
            <Box w="60%">
              <TextInput
                defaultValue={itemDetails?.tool_dimensions_w_l_h}
                isDisabled={route.params?.status !== "Em Preenchimento"}
                onChangeText={(value: string) =>
                  setItemDetails({
                    ...itemDetails,
                    tool_dimensions_w_l_h: value,
                  })
                }
              />
            </Box>
          </Stack>
          <Divider />

          <Stack
            direction="row"
            justifyContent="space-between"
            p={2}
            alignItems="center"
          >
            <Box w="40%">
              <Text>Nº OSF (opcional)</Text>
            </Box>
            <Box w="60%">
              <TextInput
                defaultValue={itemDetails?.inventory_number_imo_osf}
                isDisabled={route.params?.status !== "Em Preenchimento"}
                onChangeText={(value: string) =>
                  setItemDetails({
                    ...itemDetails,
                    inventory_number_imo_osf: value,
                  })
                }
              />
            </Box>
          </Stack>
          <Divider />

          <Stack
            direction="row"
            justifyContent="space-between"
            p={2}
            alignItems="center"
          >
            <Box w="40%">
              <Text>Nº Nota Fiscal de Comodato</Text>
            </Box>
            <Box w="60%">
              <TextInput
                defaultValue={itemDetails?.invoice_number}
                isDisabled={route.params?.status !== "Em Preenchimento"}
                onChangeText={(value: string) =>
                  setItemDetails({ ...itemDetails, invoice_number: value })
                }
              />
            </Box>
          </Stack>
          <Divider />

          <Stack
            direction="row"
            justifyContent="space-between"
            p={2}
            alignItems="center"
          >
            <Box w="40%">
              <Text>Localização (Ender) do Ativo</Text>
            </Box>
            <Box w="60%">
              <TextInput
                defaultValue={itemDetails?.asset_location}
                isDisabled={route.params?.status !== "Em Preenchimento"}
                onChangeText={(value: string) =>
                  setItemDetails({ ...itemDetails, asset_location: value })
                }
              />
            </Box>
          </Stack>
          <Divider />

          <Stack
            direction="row"
            justifyContent="space-between"
            p={2}
            alignItems="center"
          >
            <Box w="40%">
              <Text>Nº do Pedido de Compras</Text>
            </Box>
            <Box w="60%">
              <TextInput
                defaultValue={itemDetails?.purchase_doc_number}
                isDisabled={route.params?.status !== "Em Preenchimento"}
                onChangeText={(value: string) =>
                  setItemDetails({ ...itemDetails, purchase_doc_number: value })
                }
              />
            </Box>
          </Stack>
          <Divider />

          {(itemDetails?.files?.length > 0 ||
            route.params?.status === "Em Preenchimento") && (
              <>
                <Stack
                  _web={{ px: 10 }}
                  direction="row"
                  space={3}
                  justifyContent="space-between"
                  px={2}
                  py={3}
                ></Stack>
                <Box px={2} py={2} _web={{ px: 10 }}>
                  <Pressable
                    onPress={uploadFile}
                    isDisabled={route.params?.status !== "Em Preenchimento"}
                  >
                    <VStack
                      direction="row"
                      space={2}
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Stack direction="row" space={2} alignItems="center">
                        <Text fontWeight="bold" w={240}>
                          {route.params?.status === "Em Preenchimento"
                            ? "Anexo (Inserir Documento)"
                            : "Anexo"}
                        </Text>
                      </Stack>
                      {route.params?.status === "Em Preenchimento" && (
                        <Box>
                          <Icon
                            as={FontAwesome}
                            name="pencil-square-o"
                            size={8}
                            color={colors.success[50]}
                          />
                        </Box>
                      )}
                    </VStack>
                  </Pressable>

                  {files?.map((file: any, index) => (
                    <>
                      <Divider />
                      <VStack
                        direction="row"
                        space={2}
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Stack direction="row" space={2} alignItems="center">
                          <Text w={240}>{file.name}</Text>
                        </Stack>
                        <Pressable onPress={() => handleRemoveFile(index)}>
                          <Icon
                            as={FontAwesome}
                            name="trash"
                            size={8}
                            color={colors.red[600]}
                          />
                        </Pressable>
                      </VStack>
                    </>
                  ))}
                  {itemDetails?.files?.map((fileUrl: string) => (
                    <>
                      <Divider />
                      <VStack
                        direction="row"
                        space={2}
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Stack direction="row" space={2} alignItems="center">
                          <Text w={240}>
                            {fileUrl.split("?")[0].split("/").pop()}
                          </Text>
                        </Stack>
                        <Pressable onPress={() => downloadFile(fileUrl)}>
                          <Icon
                            as={FontAwesome}
                            name="download"
                            size={8}
                            color={colors.success[50]}
                          />
                        </Pressable>
                      </VStack>
                    </>
                  ))}
                </Box>
              </>
            )}
        </ScrollView>

        <Divider />

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          space={2}
          py={2}
          _web={{ justifyContent: "space-between" }}
        >
          <Button
            _web={{ w: "50%" }}
            w="48%"
            label="VOLTAR"
            onPress={navigation.goBack}
          />
          <Button
            _web={{ w: "50%" }}
            w="48%"
            label="GRAVAR"
            isDisabled={route.params?.status !== "Em Preenchimento"}
            onPress={() => saveDetail()}
          />
        </Stack>
      </VStack>
      <Spinner visible={isLoading} />
    </>
  );
};
