import { useCallback, useState } from "react";
import { Divider, CircleStatus, Button } from "../../../../components";
import { Text, VStack, Stack, Box, useTheme, FlatList, Icon } from "native-base";
import { Pressable } from "react-native";
import { useDivestmentContext, useSupplierContext } from "../../../../contexts";
import { deleteDivestimentItem, getDisinvestmentHeader, getDivestimentDetailList } from "../../../../services/divestimentService";
import Spinner from "react-native-loading-spinner-overlay/lib";
import moment from "moment";
import { DrawerNavigationProp } from "@react-navigation/drawer";
import { ParamListBase, RouteProp, useFocusEffect } from "@react-navigation/native";
import { FontAwesome } from "@expo/vector-icons";
import { platformWeb } from "../../../../utils";
interface IDisinvestmentHeader {
  creation_date: string;
  status: string;
  id: string;
  disinvestment_order_header_status_id: number;
}
interface IDisinvestmentItem {
  id: string;
  asset_number: string;
  asset_description: string;
  disinvestment_order_detail_status_id: number;
}

export const Content = ({
  route,
  navigation,
}: {
  route: RouteProp<ParamListBase, string>;
  navigation: DrawerNavigationProp<ParamListBase>;
}) => {
  const { selectedDivestmentItens, setSelectedDivestmentItens } =
    useDivestmentContext();
  const [isLoading, setIsLoading] = useState(false);
  const [disinvestmentHeaderInfo, setDisinvestmentHeaderInfo] =
    useState<IDisinvestmentHeader>();
  const { colors } = useTheme();
  const { currentSupplier } = useSupplierContext();
  const { headerId } = route.params;

  async function getDisinvestmentDetails() {
    if (headerId) {
      const headerData = await getDisinvestmentHeader(headerId);
      const listData = await getDivestimentDetailList(headerId);
      if (headerData?.status) {
        setDisinvestmentHeaderInfo(headerData.result);
      }
      if (listData?.status) {
        setSelectedDivestmentItens(listData.result);
      }
    }
    setIsLoading(false);
  }

  useFocusEffect(
    useCallback(() => {
      const getDisinvestmentItemList = async () => {
        setIsLoading(true);
        try {
          getDisinvestmentDetails();
        } catch (error) {
          console.error("Erro ao carregar dados:", error);
        } finally {
          setIsLoading(false);
        }
      };
      getDisinvestmentItemList();
    }, [route])
  );

  async function handleDelete(itemId: string) {
    setIsLoading(true);
    await deleteDivestimentItem(itemId);
    await getDisinvestmentDetails();
    setIsLoading(false);
  }

  return (
    <>
      <VStack flex={1} _web={{ px: 20, maxW: "100%" }} bg="#fff">
        {disinvestmentHeaderInfo ? (
          <Stack
            direction="row"
            space={2}
            alignItems="center"
            justifyContent="space-between"
            px={3}
            py={5}
          >
            <Text>
              {`${moment(disinvestmentHeaderInfo.creation_date).format(
                "DD/MM/YYYY"
              )}`}{" "}
              {`${disinvestmentHeaderInfo?.status}`}
            </Text>
            <Text>Id: {`${disinvestmentHeaderInfo?.id}`}</Text>
          </Stack>
        ) : (
          <Stack
            direction="row"
            space={2}
            alignItems="center"
            justifyContent="space-between"
            px={3}
            py={5}
          ></Stack>
        )}

        <VStack
          direction="row"
          space={2}
          alignItems="center"
          bg={colors.primary[50]}
          px={3}
          py={2}
          justifyContent={"space-between"}
        >
          <Box minWidth={24} _web={{ minWidth: 200 }}>
            <Text color={colors.white}>CAF</Text>
          </Box>
          <Box minWidth={"35%"} _web={{ minWidth: "55%" }}>
            <Text color={colors.white}>Descrição</Text>
          </Box>
          <Box minWidth={30} _web={{ minWidth: 20 }}>
            <Text color={colors.white}>Status</Text>
          </Box>
          {disinvestmentHeaderInfo?.status === "Em Preenchimento" && (
            <Box minWidth={30} _web={{ minWidth: 20 }}>
              <Text color={colors.white}>Remover</Text>
            </Box>
          )}
        </VStack>

        <Box flex={1} _web={{ maxH: "lg", overflow: "hidden" }}>
          <FlatList
            nestedScrollEnabled
            data={selectedDivestmentItens}
            contentContainerStyle={{
              marginBottom: 100,
            }}
            renderItem={({ item }) => {
              return (
                <Pressable
                  onPress={() =>
                    navigation.navigate("DisinvestmentItemDetailScreen", {
                      detailId: item.id,
                      headerId,
                      status: disinvestmentHeaderInfo?.status,
                    })
                  }
                >
                  <Box px={4} py={1} _web={{ px: 1 }}>
                    <VStack
                      direction="row"
                      space={2}
                      alignItems="center"
                      justifyContent={"space-between"}
                    >
                      <Box w={110} _web={{ minWidth: 200 }}>
                        <Text>{item?.asset_number}</Text>
                      </Box>
                      <Box w={"35%"} _web={{ minWidth: "55%" }}>
                        <Text>{item?.asset_description}</Text>
                      </Box>
                      {
                        <Box minWidth={30} _web={{ minWidth: 20 }}>
                          <CircleStatus
                            color={
                              item?.disinvestment_order_detail_status_id === 2
                                ? colors.success[100]
                                : colors.warning[50]
                            }
                          />
                        </Box>
                      }
                      {disinvestmentHeaderInfo?.status ===
                        "Em Preenchimento" && (
                          <Box w={50} _web={{ w: 70 }} paddingLeft={3}>
                            <Pressable onPress={() => handleDelete(item.id)}>
                              <Icon
                                as={FontAwesome}
                                name="trash"
                                color={colors.primary[200]}
                                size={7}
                                alignItems="center"
                              />
                            </Pressable>
                          </Box>
                        )}
                    </VStack>
                    <Box mt={3}>
                      <Divider />
                    </Box>
                  </Box>
                </Pressable>
              );
            }}
          />
        </Box>

        <Stack
          direction="row"
          space={2}
          alignItems="center"
          justifyContent="space-between"
          px={3}
          py={2}
          _web={{
            mt: 4,
            justifyContent: "space-between",
            position: "sticky",
            bottom: 0,
            left: 0,
            right: 0,
            background: "#fff",
          }}
        >
          {platformWeb ? (
            <Button
              label="Voltar"
              w="33%"
              onPress={() =>
                navigation.navigate("DisinvestmentScreen", {
                  currentSupplier,
                })
              }
            />
          ) : null}
          <Button
            label="SELECIONAR ITENS"
            w="49%"
            isDisabled={
              disinvestmentHeaderInfo?.status !== "Em Preenchimento"
            }
            _web={{ w: "33%" }}
            onPress={() =>
              navigation.navigate("DisinvestmentSelectItemListScreen", {
                headerId,
                selectedDivestmentItens,
              })
            }
          />
          <Button
            label="Ver Sumário"
            isDisabled={!selectedDivestmentItens?.every((d) => d.disinvestment_order_detail_status_id === 2) || selectedDivestmentItens?.length === 0}
            w="49%"
            _web={{ w: "33%" }}
            onPress={() =>
              navigation.navigate("DisinvestmentItemSummaryScreen", {
                headerId,
              })
            }
          />
        </Stack>
      </VStack>
      <Spinner visible={isLoading} />
    </>
  );
};
